import * as React from 'react';

interface Props {
  src: string;
  className?: string;
  alt?: string;
  /**
   * Quick way to set `sizes` on the rendered img, 1200 by default. Use `sizes` prop for more granular control.
   */
  maxWidth?: number;
  sizes?: string;
}

/**
 * Constructs srcsets for Cloudinary images with known widths, falls back to plain `<img>` otherwise.
 */
const Image: React.SFC<Props> = props => {
  const { maxWidth = 1200 } = props;
  const parsedUrl = parseCloudinaryUrl(props.src);
  const altAndClassName = {
    alt: props.alt,
    className: props.className,
  };

  return !parsedUrl || parsedUrl.path.endsWith('.svg') ? (
    <img src={props.src} {...altAndClassName} />
  ) : !parsedUrl.width ? (
    <img src={getCloudinaryUrl(parsedUrl)} {...altAndClassName} />
  ) : (
    <img
      srcSet={getSrcSet(parsedUrl)}
      sizes={getSizes(parsedUrl.width, maxWidth, props.sizes)}
      src={getCloudinaryUrl(parsedUrl)}
      {...altAndClassName}
    />
  );
};

function getSrcSet(parsedUrl: ParsedUrl) {
  // Note that srcset contains all sizes, even if they seem unlikely to be used with current `sizes`.
  // For example, when `sizes` dictates 400px, you "only" need DPR 3.0 for 1200px image to be fetched.
  const widths = getWidths(parsedUrl.width!);
  return widths.map(width => `${getCloudinaryUrl(parsedUrl, width)} ${width}w`).join(', ');
}

function getSizes(intrinsicImageWidth: number, maxWidthFromProps: number, sizes?: string) {
  if (sizes) {
    return sizes;
  }
  const maxWidth = Math.min(intrinsicImageWidth, maxWidthFromProps);
  return `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`;
}

function getCloudinaryUrl(parsedUrl: ParsedUrl, fixedWidth?: number) {
  const { initialPart, transformations, path } = parsedUrl;

  let transformationsPart = `${transformations ? '/' + transformations : ''}`;
  transformationsPart += '/f_auto,q_auto';
  if (fixedWidth && fixedWidth !== parsedUrl.width!) {
    transformationsPart += `,w_${fixedWidth}`;
  }

  return `${initialPart}${transformationsPart}/${path}`;
}

function getWidths(maxWidth: number) {
  const result = [];
  const step = 400;
  // For example, if the original image is 820px, it's unnecessary to generate the 800px version.
  // This constant defines the range in pixels where this skipping happens.
  const rangeWhereNotToGenerateDownscaledImage = 199;
  let next = 0;

  while (next + step < maxWidth - rangeWhereNotToGenerateDownscaledImage) {
    result.push((next = next + step));
  }
  result.push(maxWidth);

  return result;
}

interface ParsedUrl {
  /**
   * Always 'https://res.cloudinary.com/vpimg/image/upload'
   */
  initialPart: string;

  /**
   * E.g., `w_100` or `w_100/w_200` (chained transformations).
   */
  transformations?: string;

  /**
   * Width, if indicated in the URL. It's the right-most one, e.g., `w_100/w_200` is 200px wide.
   */
  width?: number;

  /**
   * Something like `v1234/versionpress.com/example.jpg`.
   */
  path: string;
}

function parseCloudinaryUrl(url: string): ParsedUrl | null {
  // https://regex101.com/r/bSDDyn/7/
  const parsedUrl = /^(https:\/\/res\.cloudinary\.com\/vpimg\/image\/upload)\/?((?:(?!v\d).)+)?\/(v\d+\/.*)$/.exec(url);
  if (!parsedUrl) {
    return null;
  }
  const [, initialPart, transformations, path] = parsedUrl;

  let width: number | undefined;
  if (transformations) {
    // https://regex101.com/r/H9wlG8/2
    const lastWidth = /w_(\d+)(?!.*w_\d+)/.exec(transformations);
    if (lastWidth) {
      width = Number(lastWidth[1]);
    }
  }

  return {
    initialPart,
    transformations,
    width,
    path,
  };
}

export default Image;

// mainly for tests
export { parseCloudinaryUrl, ParsedUrl };
