import * as React from 'react';

import Link from '../Link';
import Image from '../Image';

interface Props {
  title: React.ReactNode;
  /**
   * If route is given, title will be a link
   */
  titleLink?: string;
  subtitle?: React.ReactNode;
  className?: string;
}

/**
 * Larger header with image in background
 */
const FancyPageHeader: React.StatelessComponent<Props> = ({ title, titleLink, className = '', subtitle }) => {
  const titleComponent = <h1 className='heading heading-inverse heading-xl strong-600 line-height-1_2'>{title}</h1>;

  return (
    <section className={`fancy-page-header slice-lg slice--offset-top has-bg-cover bg-size-cover ${className}`}>
      <Image
        src='https://res.cloudinary.com/vpimg/image/upload/w_1200,c_crop,g_west,h_376,q_20,y_30/v1538741335/versionpress.com/default-page-header.jpg'
        className='background-image'
      />

      <span className='mask mask-base-1--style-1' />
      <div className='container text-center'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='py-5'>
              {titleLink ? <Link to={titleLink}>{titleComponent}</Link> : titleComponent}
              <p className='lead c-white mt-3 fluid-paragraph'>{subtitle}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FancyPageHeader;
