import * as React from 'react';
import Layout from '../layouts/Layout';
import Helmet from 'react-helmet';
import FancyPageHeader from '../components/shared/FancyPageHeader';
import Link from '../components/Link';

const GetStartedPage: React.StatelessComponent = () => (
  <Layout className='get-started'>
    <Helmet>
      <title>Get Started</title>
    </Helmet>
    <FancyPageHeader title='Get Started' />

    <section className='slice-xs'>
      {/* <div className='container'>
        <div className='row'> */}
      <div className='how-to-join m-auto'>
        <div className='step'>
          <div className='heading h4'>Sign up</div>
          <p>Register for a free account using the button below.</p>
        </div>
        <div className='step'>
          <div className='heading h4'>Use free development sites</div>
          <p>
            They are an awesome and free way to familiarize yourself with the platform. Development sites get all the
            functionality except production domains.
          </p>
        </div>
        <div className='step'>
          <div className='heading h4'>Upgrade when ready</div>
          <p>The portal will guide you through the process.</p>
        </div>
      </div>
      {/* </div>
      </div> */}
    </section>
    <section className='slice'>
      <div className='container'>
        <div className='row'>
          <div className='col text-center'>
            <p>Sorry, new signups are disabled. <Link to='/docs'>Learn more</Link>.</p>
            <Link to='https://my.versionpress.com/register' className='btn btn-styled btn-xl btn-base-2 btn-circle disabled'>
              Let's go!
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default GetStartedPage;
